import styled from 'styled-components'
import {Box} from 'rebass'
import {MEDIA_WIDTHS, device} from '../../theme/theme'
import {RightArrowButton} from '../Button/Button'
import {GENERAL_LINKS} from '../../constants/constants'
import HeroChart from '../../assets/images/hero-chart.png'
import OptimizedHeroChart from '../../assets/images/hero-background-ai.jpg'
import HeroImageDesktop from '../../assets/images/hero-image-desktop.png'
import HeroImageMobile from '../../assets/images/hero-image-mobile.png'
import OptimizedHeroBackground from '../../assets/images/optimized-hero-background.jpg'
import { useEffect, useState } from 'react'

const HeroContainer = styled.div`
  display: flex;
  min-height: 350px;
  background: url(${OptimizedHeroBackground});
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  padding: 160px 16px 45px;

  @media ${device.sm} {
    background-position: center;
    min-height: 480px;
  }
`

const SplitViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1115px;
`

const PrimaryViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;

  @media ${device.sm} {
    width: 50%;
  }
`

const SecondaryViewContainer = styled.div`
  display: none;

  @media ${device.sm} {
    display: flex;
    flex-direction: column;
    width: 50%;
    max-width: 50%;
    align-items: center;
  }
`

const Title = styled.div`
  font-family: 'Inter', san-serif;
  font-weight: 600;
  font-size: 48px;
  margin-bottom: 24px;
  max-width: 300px;

  @media ${device.sm} {
    font-size: 69px;
    max-width: none;
  }
`

const Description = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #7b7b7b;
  margin-bottom: 36px;
  line-height: 160%; /* 32px */

  @media ${device.sm} {
    font-size: 20px;
  }
`

const HeroStatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 350px;
`

const StatContainer = styled.div`
  display: flex;
  align-items: center;
`

const StatNumber = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-right: 8px;
`

const StatDescription = styled.div`
  font-family: 'Be Vietnam Pro', sans-serif;
  font-size: 12px;
  color: #7d7a76;
`

const MainImage = styled.img`
  max-width: 450px;
`

const HeroStats = () => {
  return (
    <HeroStatsContainer>
      <StatContainer>
        <StatNumber>1400+</StatNumber>
        <StatDescription>Users Active</StatDescription>
      </StatContainer>
      |
      <StatContainer>
        <StatNumber>20+</StatNumber>
        <StatDescription>Data Streams</StatDescription>
      </StatContainer>
    </HeroStatsContainer>
  )
}

const HeroImage = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MEDIA_WIDTHS.sm);
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  console.log({isMobile})

  return (
    <div style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
      <img
        src={isMobile ? HeroImageMobile : HeroImageDesktop}
        alt="Hero"
        style={{ width: isMobile ? '375px' : '537px', paddingBottom: '25px' }}
      />
    </div>
  );
};

const Hero = () => {
  return (
    <HeroContainer>
      <SplitViewContainer>
        <PrimaryViewContainer>
          <Title>
            The Trade Everything Engine
          </Title>
          <Description>The Exotic Perps Dex</Description>
          <HeroImage />
          <Box style={{marginBottom: '40px'}}>
            <RightArrowButton href={GENERAL_LINKS.LAUNCH_APP}>Start Trading</RightArrowButton>
          </Box>
        </PrimaryViewContainer>
        <SecondaryViewContainer>
          <MainImage src={OptimizedHeroChart} />
        </SecondaryViewContainer>
      </SplitViewContainer>
    </HeroContainer>
  )
}

export default Hero
